@use "global" as g; 

//================
// Our Story
//================
#our-story {

/* ***** KV **************** */
#kv {
	@include g.flex();
	align-items: center;
	padding: 0;
	#kvimg {
		width: 50%;
		padding-top: 50%;
		border-bottom-right-radius: 15vw;
	}
	#kv_title {
		width: 50%;
		padding: var(--gap);
		h1 {
			@include g.head1();
		}
		.txt {
			padding-top: var(--gap2);
		}
	}
}

@include g.mq(w950) {
	#kv {
		align-items: stretch;
		#kv_title {
			padding-top: 14rem;
		}
	}	
}

@include g.mq(sp) {
	#kv {
		#kvimg {
			position: relative;
			width: 100%;
			padding-top: 120%;
			border-bottom-right-radius: 30vw;
		}
		#kv_title {
			width: 100%;
			padding: 30px;
			.txt {
				padding-top: var(--gap3);
			}	
		}
	}
}

.txtblc {
	width: 60%;
	margin-bottom: calc(var(--sec)*1.5);
	h3 {
		@include g.head2();
	}
	.txt {
		padding-top: var(--gap2);
		color: var(--dkgrey);
	}
	.btn {
		margin-top: var(--gap2);
		color: #000;
		background: var(--blue);
	}
}
@include g.mq(sp) {
	.txtblc {
		width: 100%;
		margin-bottom: calc(var(--sec));
		h3 {
			font-size: 3.8rem;
		}
	}
}


/* ***** Text block section **************** */
#txt_blc_sec {
	h2 {
		margin-bottom: var(--sec);
		padding: 0 var(--gap3);
		@include g.subh2();
		text-align: center;
	}
	.blockwrap {
		flex-direction: column;
	}
	#blc2 {
		margin-left: auto;
	}
	#logoslider {
		mix-blend-mode: darken;
		#logoSwiper {
			@include g.logoSwiper();
		}
	}
}
#parawrap1 {
	height: 100%;
}
#para1 {
	width: 32vw;
	top: 15%;
	left: 88vw;
	z-index: 1;
}
#para2 {
	width: 28vw;
	top: 30%;
	left: 70vw;
	z-index: 0;
}
#para3 {
	width: 17vw;
	top: 60%;
	left: 15vw;
	z-index: 0;
}
#para4 {
	width: 22vw;
	top: 90%;
	left: 90vw;
	z-index: 0;
}

@include g.mq(sp) {
	#txt_blc_sec {
		h2 {
			margin-top: 60vh;
			font-size: 2.8rem;
		}
		.blockwrap {
			flex-direction: column;
		}
		#blc2 {
			margin-left: auto;
		}
		#logoslider {
			mix-blend-mode: darken;
			#logoSwiper {
				@include g.logoSwiper();
			}
		}
	}
	
	#parawrap1 {
		height: 80vh;
	}
	#para1 {
		width: 70vw;
		top: 10%;
		left: 80vw;
		z-index: 1;
	}
	#para2 {
		width: 60vw;
		top: 65%;
		left: 40vw;
		z-index: 0;
	}
	#para3 {
		width: 44vw;
		top: 110%;
		left: 10vw;
		z-index: 0;
	}
	#para4 {
		width: 44vw;
		top: 110%;
		left: 90vw;
		z-index: 0;
	}
}



/* ***** Our process **************** */
#our-process {
	text-align: center;
	.blockwrap {
		display: block;
	}
	.txtblc {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		margin-bottom: var(--gap);
	}
}

#process_feature {
	width: 30rem;
	height: 30rem;
	position: relative;
	margin: 0 auto;
	margin-top: calc(var(--sec)*3);
	margin-bottom: 10rem;
	&:before {
		@include g.bfaf();
		border: 1px solid #000;
		border-radius: 50%;
	}
	.item_img {
		position: absolute;
		width: 12rem;
		padding: 1rem;
		background: var(--wh1);
		p {
			display: none;
		}
	}
	#item_img1 {
		top: 0;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	#item_img2 {
		top: 15rem;
		left: 50%;
		transform: translate(calc(-15rem - 30%),0);
	}
	#item_img3 {
		top: 15rem;
		left: 50%;
		transform: translate(calc(15rem - 80%),0);
	}

	.item_txt {
		width: 100%;
		position: absolute;
		h3 {
			margin-bottom: 1rem;
			@include g.subh2();
		}
	}
	#item_txt1 {
		top: 0;
		left: 50%;
		transform: translate(-50%,-150%);
	}
	#item_txt2 {
		top: 60%;
		left: -5rem;
		transform: translate(-100%,0);
	}
	#item_txt3 {
		top: 60%;
		right: -5rem;
		transform: translate(100%,0);
	}
}

#parawrap2 {
	height: 100vh;
	z-index: 0;
}
#para5 {
	width: 35vw;
	top: 40%;
	left: 5vw;
	z-index: 2;
}
#para6 {
	width: 35vw;
	top: 95%;
	left: -5vw;
	z-index: 1;
}
#para7 {
	width: 12vw;
	top: 100%;
	left: 90vw;
}

@include g.mq(sp) {
	#process_feature {
		width: 100%;
		height: auto;
		margin-top: 50vh;
		padding-top: 30rem;
		&:before {
			width: 24rem;
			height: 24rem;
			left: 50%;
			transform: translate(-50%,0);
		}	
		.item_img {
			width: 12rem;
		}
		#item_img2 {
			top: 10rem;
			transform: translate(calc(-12rem - 30%),0);
		}
		#item_img3 {
			top: 10rem;
			transform: translate(calc(12rem - 80%),0);
		}
	
		.item_txt {
			position: relative;
			margin-bottom: var(--gap);
		}
		#item_txt1 {
			top: auto;
			left: auto;
			transform: translate(0,0);
		}
		#item_txt2 {
			top: auto;
			left: auto;
			transform: translate(0,0);
		}
		#item_txt3 {
			top: auto;
			right: auto;
			transform: translate(0,0);
		}
	}
	
	#parawrap2 {
		height: 100%;
	}
	#para5 {
		width: 60vw;
		top: 20%;
		left: 25vw;
	}
	#para6 {
		width: 60vw;
		top: 30%;
		left: 0vw;
		z-index: 1;
	}
	#para7 {
		width: 24vw;
		top: 100%;
		left: 90vw;
	}
}


}




