@use "global" as g; 

//================
// Archive
//================
.archive {
	h1 {
		width: 100%;
		padding: var(--sec) 0;
		@include g.head1();
		text-align: center;
	}
}


//================
// Archive List
//================
#archive_list {
	width: 100%;
	@include g.flex();
	&:after,
	.item {
		width: calc((100% - var(--gap3)) / 2);
	}
	&:after {
		content: "";
		display: block;
	}
	.item {
		margin-bottom: var(--gap);
		.img {
			width: 100%;
			padding-top: 67%;
			position: relative;
			overflow: hidden;
			img {
				@include g.imgcover();
				transition: transform 0.8s;
			}
		}
		.date {
			padding-top: 20px;
			padding-bottom: 10px;
		}
		h2 {
			padding-right: var(--gap);
			@include g.subh2();
		}
		a {
			width: 100%;
			&:hover {
				img {
					transform: scale(1.1);
				}
			}	
		}
	}
}

#basic_archive {
	.blockwrap {
		max-width: 100%;
	}
	#archive_list:after,
	.item {
		@include g.mq(min1440) {
			width: calc((100% - var(--gap3)*2) / 3);
		}
	}	
}


@include g.mq(sp) {
	#archive_list {
		&:after,
		.item {
			width: 100%;
		}
		.item {
			margin-bottom: var(--gap);
			.date {
				padding-top: 10px;
				padding-bottom: 5px;
			}
			h2 {
				padding-right: 0;
				font-size: 3rem;
			}
		}
	}	
}


//================
// Resource Archive
//================
#resources_archive {
	#kv {
		padding: 0;
		margin-bottom: var(--sec);
	}
	#kvimg {
		width: 100%;
		padding-top: 50%;
		iframe {
			width: 100%;
			height: auto;
		}
	}
	.title_blc {
		@include g.middle_title();
	}
	#kv_title {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0;
		transform: translate(-50%,-50%);
		text-align: center;
		color: #fff;
	}
	.archive_sec {
		padding-top: 0;
	}
	.btnblc {
		width: 100%;
	}
	.btn {
		padding: 0 100px;
	}
}

@include g.mq(sp) {
	#resources_archive {
		#kvimg {
			padding-top: 100vh;
			padding-top: 100lvh;
			iframe {
				width: auto;
				height: 100%;
			}
		}
		#kv_title {
			text-align: center;
			h1 {
				text-align: center;
			}
			.txt {
				padding: 0 var(--gap3);
			}
		}
	}
}



