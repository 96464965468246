@use "global" as g; 


/* ***** swiper **************** */
.swiper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	.img {
		width: 100%;
		height: 100%;
		@include g.bg();
	}
}
.swiper-wrapper {
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.swnav {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items:center;
}
.swiper-button-next,
.swiper-button-prev {
	width: 20px;
	height: 20px;
	position: relative;
	top: auto;left: auto;right: auto;
	margin: 0;
	opacity: 1;
	transition: 0.3s;
	&:after {
		content:'';display: block;
		width: 100%;height: 100%;
		border-top: 1px solid #000;
		border-right: 1px solid #000;
		transform: scale(1,0.6) rotate(45deg);
	}
	&:hover {
		opacity: 0.6;
	}
}
.swiper-button-prev:after {
	transform: scale(-1,0.6) rotate(45deg);
}
.swiper-pagination-bullet {
	background: none;
	border: 1px solid #fff;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background: #fff;
}


/* ***** Accordion **************** */
.ex {
	.exbtn {
		@include g.flex();
		align-items: center;
		@include g.tra();
		padding: 0;
		cursor: pointer;
		span {
			position: relative;
			&:before,&:after {
				@include g.bfaf();
				width: 8px;height: 1px;
				top: 50%;left: auto;right: -20px;
				background: #000;
				transform: translate(-50%,-50%);
				@include g.tra();
				@include g.mq(pc) {
					display: none;
				}
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	.exc {
		@include g.flex();
		height: 0;
		padding: 0;
		overflow: hidden;
		p {
			width: 100%;
		}
	}
	&.open > {
		.exbtn {
			span {
				&:after {
					transform: translate(-50%,-50%) rotate(0deg);
				}
			}
		}
	}
}

#footer {
	.ex {
		.exbtn {
			span {
				width: 100%;
				&:before,&:after {
					@include g.bfaf();
					width: 8px;height: 1px;
					top: 50%;
					left: auto;
					right: 0;
					background: #fff;
					transform: translate(-50%,-50%);
					@include g.tra();
					@include g.mq(pc) {
						display: none;
					}
				}
				&:after {
					transform: translate(-50%,-50%) rotate(90deg);
				}
			}
		}
	}
}
@include g.mq(pc) { // PC //
	#nav,#footer {
		.ex {
			.exc {
				height: auto !important;
				overflow: auto;	
			}
			&.open > {
				.exc {
					padding-bottom: 0;
				}
			}
		}	
	}
}


/* ***** Box parallax **************** */
.parawrap {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	.para {
		position: absolute;
		// transform: translate(-50%,-50%);
	}
}


.btnblc {
	text-align: center;
}
.btn {
	@include g.flex_center();
	display: inline-flex;
	height: 36px;
	padding: 0 60px;
	font-family: "acumin-pro",sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
	text-align: center;
	color: #fff;
	border-radius: 0 18px 0 18px;
	background: var(--orange);
	transition: 0.2s;
	cursor: pointer;
	&:hover {
		color: #fff;
		border-radius: 18px 0 18px 0;
	}
	&.l {
		height: 50px;
		padding: 0 40px;
		font-size: 1.65rem;
	}
}
@include g.mq(sp) {
	.btn {
		&.l {
			height: 40px;
			font-size: 1.35rem;
		}
	}
}



/* ***** Scrolldown **************** */
#scrldown {
	position: relative;
	margin-top: var(--gap2);
	span {
		color: #000;
		transition: 1.2s ease-in-out;
	}
	&:hover {
		transform: translate(0,10px);
	}
	&:before,&:after {
		@include g.bfaf();
		width: 14px;
		height: 14px;
		top: 5rem;
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		transform: translate(4px, 0) rotate(45deg);
		transition: 1.2s ease-in-out;
	}
	&:after {
		top: 5.5rem;
	}
}
.wh {
	#scrldown {
		span {
			color: #fff;
		}
		&:before,&:after {
			border-color: #fff;
		}
	}
}
@include g.mq(sp) {
	#scrldown {
		margin-top: var(--gap);
		&:before,&:after {
			width: 10px;
			height: 10px;
		}
	}
}	

/* ***** video **************** */
iframe,
video {
	filter: drop-shadow(0px 0px #000);
	outline: none;
	border: none;
}
.video {
	width: 100%;
	padding-top: 56.25%;
	position: relative;
	overflow: hidden;
	iframe,
	video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}

.video_bg {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	iframe {
		aspect-ratio: 16 / 9;
		width: auto;
		height: 100%;
		position: absolute;
		top: 50%;left: 50%;
		transform: translate(-50%,-50%);
	}
}
@media (min-aspect-ratio: 16/9) {
	.video-bg {
		iframe {
			width: 100%;
			height: calc(100vw * (16/9));
		}
	}
}



/* ***** kvimg **************** */
#kvimg {
	width: 50%;
	padding-top: 50%;
	position: relative;
	overflow: hidden;
	img {
		@include g.imgcover();
	}
}


