@use "global" as g; 

//================
// whitepapers single
//================
.whitepapers_single {

/* ***** form_sec **************** */
#form_sec {
	padding-top: calc(var(--sec) * 1.5);
	&:before {
		content:'';
		display: block;
		width: 50%;
		padding-top: 200px;
		border-top-left-radius: 20vw;
		background: var(--dkblue2);
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.txt_blc {
		width: 55%;
		h2 {
			padding-bottom: var(--gap);
			@include g.head2();
		}
		.lead {
			margin-bottom: var(--gap2);
			padding-left: var(--gap3);
			padding-right: var(--gap);
			font-size: 2rem;
			font-weight: bold;
			border-left: 2px solid var(--orange);
		}
		.txt {
			padding-right: var(--gap);
			padding-bottom: var(--gap2);
		}
		img {
			width: 75%;
		}
	}
	#form_blc {
		width: 45%;
		padding-left: var(--gap2);
	}
}
@include g.mq(sp) {
	#form_sec {
		padding-bottom: 200px;
		&:before {
			content:'';
			display: block;
			width: 100%;
			padding-top: 140px;
			border-top-left-radius: 20vw;
			background: var(--dkblue2);
			position: absolute;
			right: 0;
			bottom: 0;
		}
	
		.txt_blc {
			width: 100%;
			margin-bottom: var(--sec);
			h2 {
				padding-bottom: var(--gap3);
			}
			p {
				padding-right: 0;
				padding-bottom: var(--gap3);
			}
			img {
				width: 100%;
			}
		}
		#form_blc {
			width: 100%;
			padding-left: 0;
		}
	}	
}



}