@use "global" as g; 

#nav {
	@include g.flex();
	align-items: center;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	padding: 20px 3vw;
	z-index: 99;
	@include g.nav1();
	@include g.tra();
	#contact_btn {
		@include g.flex_center();
		width: 150px;
		height: 32px;
		font-size: 1.2rem;
		font-weight: bold;
		color: #000;
		border: 1px solid #000;
		border-radius: 16px;
		@include g.tra();
		&:hover {
			border: 1px solid var(--orange);
			background: var(--orange);
			color: #fff;
		}
		@include g.mq(w1280) {
			width: 110px;
		}
		@include g.mq(w950) {
			width: 90px;
			font-size: 1.1rem;
		}
	}
	#sound_btn {
		width: 60px;
		height: 60px;
		position: relative;
		background: var(--wh2);
		border-radius: 30px;
		filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.1));
		cursor: pointer;
		@include g.tra();
		transform: translateZ(0px);
		.in {
			width: 20px;
			height: 24px;
			@include g.flex();
			align-items: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			@include g.tra();
		}
		@include g.sound_btn();
		&.off {
			@include g.sound_offbtn();
		}
	}
	#nav_btn {
		display: none;
	}
}

body.scrldown {
	#nav {
		transform: translate(0,-100px)
	}
	&.navopen {
		#nav {
			transform: translate(0,0)
		}
	}
}
body.navopen {
	overflow: hidden;
}

/* ***** Nav PC **************** */
@include g.mq(pc) {
	#nav {
		height: auto!important;
	}
	#main_nav {
		position: relative;
		@include g.flex();
		flex-wrap: nowrap;
		align-items: center;
		width: calc(100% - 100px);
		height: 76px;
		padding: 0 3vw;
		background: var(--wh2);
		border-radius: 38px;
		filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.1));
		@include g.mq(w1280) {
			width: calc(100% - 80px);
		}
		#nav_logo {
			position: relative;
			width: 240px;
			margin-right: auto;
			line-height: 0;
			z-index: 9;
			@include g.mq(w1280) {
				width: 180px;
			}
		}
		#navul {
			@include g.flex();
			align-items: center;
			li {
				margin-left: 2vw;
				@include g.mq(w1280) {
					margin-left: 10px;
				}
				@include g.mq(w950) {
					font-size: 1.2rem;
				}
				a {
					position: relative;
					text-align: center;
					color: #000;
					font-size: 1.6rem;
					@include g.mq(w950) {
						font-size: 1.4rem;
					}	
					.fwbs {
						width: 100%;
						position: absolute;
						top: 50%;left: 50%;
						transform: translate(-50%,-50%);
						@include g.tra();
					}
					&.fwb:before {
						display: block;
						content: attr(title);
						font-weight: bold;
						opacity: 0;
						@include g.tra();
					}
					&:hover {
						.fwbs {
							opacity: 0;
						}
						&:before {
							opacity: 1;
						}	
					}
				}
				&.current a {
					.fwbs {
						opacity: 0;
					}
					&:before {
						opacity: 1;
					}
				}
			}
			.navbox {
				width: 36rem;
				position: absolute;
				top: 56px;
				left: 0;
				background: var(--wh1);
				filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.1));
				@include g.flex();
				padding: 4rem 3rem;
				border-radius: 5rem;
				opacity: 0;
				pointer-events: none;
				transform: translate(0,-3rem);
				transition: 0.3s;
				li {
					width: 100%;
					margin: 0;
					margin-bottom: 15px;
					&:last-of-type {
						margin-bottom: 0;
					}
					a {
						width: 100%;
						@include g.nav_sub();
						text-align: left;
						br {
							display: none;
						}
						&:hover {
							color: var(--orange);
						}
					}
				}
			}
			.has_child {
				position: relative;
			}

			.open {
				a.fwb {
					span {
						opacity: 0;
					}
					&:before {
						opacity: 1;
					}	
				}
				.navbox {
					opacity: 1;
					pointer-events: auto;
					transform: translate(0,0);
				}
			}

		}
	}
}

/* ***** Nav mobile **************** */
@include g.mq(sp) {
	#nav {
		width: calc(100% - 40px);
		height: 64px;
		top: 20px;
		left: 20px;
		padding: 0 20px;
		border-radius: 32px;
		background: var(--wh1);
		filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.1));
		#main_nav {
			height: 100%;
		}
		#nav_logo {
			position: relative;
			width: 192px;
			position: absolute;
			top: 32px;
			left: 20px;
			line-height: 0;
			z-index: 9;
			transform: translate(0,-50%);
		}	
		#sound_btn {
			width: 48px;
			height: 64px;
			position: absolute;
			top: 0;
			right: 48px;
			border-radius: 0;
			filter: drop-shadow(0px 0px 0px rgba(0,0,0,0));
			background: none;
		}
		#nav_btn {
			display: block;
			width: 48px;
			height: 64px;
			position: absolute;
			top: 0;
			right: 10px;
			cursor: pointer;
			@include g.tra();
			.in {
				width: 20px;
				height: 20px;
				@include g.flex();
				align-items: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				@include g.tra();
			}
			i {
				display: block;
				width: 100%;
				height: 3px;
				background: #000;
				border-radius: 2px;
				position: absolute;
				top: 50%;left: 50%;
				transform: translate(0,-50%);
				@include g.tra();
				&:nth-of-type(1) {
					transform: translate(-50%,calc(-50% - 8px));
				}
				&:nth-of-type(2) {
					width: 80%;
					left: 0;
				}
				&:nth-of-type(3) {
					transform: translate(-50%,calc(-50% + 8px));
				}
			}
			.navopen & {
				i {
					&:nth-of-type(1) {
						transform: translate(-50%,-50%) rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						transform: translate(-50%,-50%) rotate(-45deg);
					}
				}
			}
		}
		#navul {
			width: 100%;
			height: calc(100% - 80px);
			margin-top: 80px;
			opacity: 0;
			pointer-events: none;
			transition: 0.4s 0.4s;
			overflow: hidden;
			.navopen & {
				opacity: 1;
				pointer-events: auto;
				overflow-y: auto;
				overscroll-behavior: contain;
			}
			li {
				width: 100%;
				padding: 20px 0;
				a {
					width: 100%;
					br {
						display: none;
					}
				}
				li {
					padding: 10px 0;
					padding-left: 20px;
					font-weight: 700;
					&:first-of-type {
						padding-top: 20px;
					}
					li {
						padding-left: 0;
						font-weight: 300;
						.all {
							font-weight: 700;
						}
					}
				}
				&.current .fwbs {
					color: var(--orange);
				}
			}
			#contact_btn {
				width: 150px;
				margin: 0 auto;
			}	
		}
	}
}


/* ***** Nav Landing page **************** */
body.lp {
	#nav {
		background: none;
		#main_nav {
			background: none;
			filter: none;
			#navul {
				display: none;
			}
		}
		#sound_btn,
		#nav_btn {
			display: none;
		}
	}
	#footer_empowering {
		display: none;
	}
}







