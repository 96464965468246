@use "global" as g; 

//================
// Top
//================
#top {
// overflow: hidden;
#parawrap1 {
	height: calc(700px + 70vh);
}
#para1 {
	width: 32vw;
	top: -15%;
	left: 5vw;
	z-index: 0;
}
#para2 {
	width: 12vw;
	top: -10%;
	right: 5vw;
	z-index: -1;
}
#para3 {
	width: 35vw;
	max-width: 700px;
	top: 30%;
	right: -20vw;
	z-index: 0;
}
#para4 {
	width: 20vw;
	top: 90%;
	left: 20vw;
}

#parawrap2 {
	height: 50vh;
	top: auto;
	bottom: 0;
}
#para5 {
	width: 12vw;
	top: 100%;
	left: 10vw;
}
#para6 {
	width: 20vw;
	max-width: 350px;
	top: 160%;
	right: -12vw;
	filter: drop-shadow(40px 40px 20px rgba(0,0,0,0.4));
}

@include g.mq(sp) {
	#parawrap1 {
		height: 200vh;
	}
	#para1 {
		width: 45vw;
		top: -20%;
		left: 10vw;
	}
	#para2 {
		width: 30vw;
		top: 20%;
		left: 85vw;
		z-index: 0;
	}
	#para3 {
		top: 15%;
		right: 40vw;
	}
	#para4 {
		top: 50%;
		left: 90vw;
		@media screen and (max-width: 560px) {
			display: none;
		}		
	}
	
	#parawrap2 {
		height: 60vh;
		top: auto;
		bottom: 0;
	}
	#para5 {
		width: 20vw;
		top: 90%;
		left: 10vw;
	}

	#para6 {
		width: 40vw;
		top: 100%;
		right: -25vw;
	}
}


/* ***** KV **************** */
#kv {
	height: 100vh;
	height: 100svh;
	@include g.kvmin();
	padding: 0;
	background: #fff;
	#kv_title {
		position: absolute;
		top: 50%;
		color: #fff;
		transform: translate(0,-50%);
		.blockwrap {
			display: block;
		}
		h1 {
			@include g.head1();
		}
		p {
			padding: var(--gap2) 0;
			@include g.body2();
		}
	}
}
#topkv {
	width: 100%;
	height: 100vh;
	height: 100svh;
	@include g.kvmin();
	position: absolute;
	top: 0;left: 0;
	pointer-events: none;
	overflow: hidden;
	background: var(--orange);
	#topkvelm {
		width: 100%;
		position: absolute;
		top: 0;left: 0;
		#elm path {
			fill: #fff;
		}
		#txt path {
			fill: #fff;
		}
	}
}
@media screen and (max-width: 1024px) {
	#kv {
		padding-top: var(--sec);
		#kv_title {
			position: relative;
			top: auto;
			padding-top: var(--gap);
			transform: translate(0,0);
		}
	}
}	

@include g.mq(sp) {
	#kv {
		padding-top: var(--gap);
		@media screen and (orientation: landscape) {
			height: 50rem;
			#topkv {
				height: 50rem;
			}
		}
		#kv_title {
			h1 {
				font-size: 4.5rem;
			}
			p {
				padding-bottom: 4rem;
			}
			.btn {
				padding: 0 30px;
			}
		}
	}
}



/* ***** Demo **************** */
#top_demo {
	padding: 0;
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 200vh;
		position: absolute;
		left: 0;bottom: -100vh;
		background: linear-gradient(var(--wh1), var(--orange), var(--wh1), var(--wh1));
		opacity: 0.1;
		z-index: -1;
	}
	#demoimg {
		width: 100%;
		filter: drop-shadow(0px 20px 20px rgba(0,0,0,0.2));
	}
	#logoslider {
		width: 100%;
		padding-bottom: calc(var(--sec)*2);
		mix-blend-mode: darken;
		p {
			padding-bottom: var(--gap2);
			font-weight: bold;
		}
		#logoSwiper {
			@include g.logoSwiper();
		}
	}
}
@include g.mq(sp) {
	#top_demo {
		padding-bottom: 0;
		h2 {
			padding: 0;
			font-size: 2.4rem;
		}
		#logoslider {
			padding-bottom: 40vh;
			p {
				font-size: 1.8rem;
				text-align: center;
			}
		}	
	}
}


/* ***** competence **************** */
#competence {
	position: relative;
	padding-top: 0;
	z-index: 1;
	.title {
		h1 {
			margin-bottom: var(--gap3);
			@include g.head2();
		}
		strong {
			font-size: 8rem;
			font-weight: 600;
			color: var(--orange);
		}
	}
	h2 {
		width: 60%;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 var(--gap3);
		@include g.subh2();
		font-size: 4.2rem;
		text-align: center;
		@media (max-width: 768px){
			width: 80%;
		}
	}
	#top_solutions {
		width: 100%;
		padding-top: 20vh;
		padding-bottom: var(--gap);
		ul {
			max-width: 900px;
			margin: 0 auto;
		}
		li {
			position: relative;
			width: 100%;
			max-width: 40rem;
			margin-bottom: var(--gap);
			padding-left: var(--gap3);
			&:nth-of-type(n+3) {
				margin-left: auto;
			}
			&:before {
				@include g.bfaf();
				width: 1px;
				background: var(--orange);
				transform: scale(1,0);
				transform-origin: top center;
				transition: 0.6s;
			}
			&.elp_on:before {
				transform: scale(1,1);
			}
			h3 {
				margin-bottom: var(--gap3);
				@include g.subh1();
				font-size: 4.2rem;
				color: var(--orange);
			}
		}
	}
}
@include g.mq(sp) {
	#competence {
		position: relative;
		padding-top: 0;
		z-index: 1;
		.title {
			strong {
				font-size: 6rem;
			}
		}
		h2 {
			font-size: 2.8rem;
		}
		#top_solutions {
			padding-top: 40vh;
			li {
				margin-bottom: var(--gap);
				&:nth-of-type(n+3) {
					margin-left: 0;
				}
				h3 {
					margin-bottom: var(--gap3);
					@include g.subh1();
					font-size: 4.2rem;
					color: var(--orange);
				}
			}
		}
	}
}


/* ***** performance **************** */
#performance {
	position: relative;
	color: #fff;
	background: linear-gradient(45deg, #193752, #11293F);
	z-index: 0;
	.title {
		max-width: 420px;
		margin: 0 auto;
		padding-bottom: var(--gap);
		text-align: center;
		h2 {
			margin-bottom: var(--gap3);
			@include g.subh1();
		}
	}
	#performance_list {
		width: 100%;
		@include g.flex();
		.perform {
			width: calc(100% / 3);
			text-align: center;
			.col {
				padding: var(--gap2);
			}
			.col1 {
				min-height: 260px;
				padding: var(--gap3) 0;
				color: #fff;
				font-size: 2.6rem;
				font-weight: 600;
				.num {
					margin-bottom: 10px;
					@include g.head1();
					font-size: 9rem;
					color: var(--orange);
				}
			}
			&:nth-of-type(2) {
				.col1 {
					border-left: 1px solid var(--wh3);
					border-right: 1px solid var(--wh3);	
				}
			}	
		}
	} 
}
@include g.mq(sp) {
	#performance {
		.title {
			h2 {
				margin-bottom: var(--gap);
			}
		}
		#performance_list {
			@include g.flex();
			.perform {
				width: 100%;
				margin-top: 40px;
				&:first-of-type {
					margin-top: 0;
				}
				.col {
					padding: var(--gap2);
				}
				.col1 {
					min-height: 0;
					font-size: 2.1rem;
					.num {
						font-size: 5.6rem;
					}
				}
				&:nth-of-type(2) {
					.col1 {
						border: 0;
					}
				}	
			}
		} 
	}
}


#top_news {
	.title_blc {
		@include g.middle_title();
		.txt {
			max-width: 500px;
		}
	}
	.btnblc {
		width: 100%;
	}
}

/* ***** learning **************** */
#learning {
	padding-top: var(--sec);
	padding-bottom: calc(var(--sec)*2);
	#learn_img {
		position: relative;
		width: 100%;
		padding-top: 45%;
		overflow: hidden;
		&:after {
			@include g.bfaf();
			background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0));
		}
		.paraimg {
			width: 100%;
			padding-top: 100%;
			position: absolute;
			top: 0;left: 0;
			img {
				@include g.imgcover();
				object-position: center top;
			}
		}
		.title {
			max-width: 600px;
			position: absolute;
			top: var(--gap2);
			left: var(--gap2);
			color: #fff;
			z-index: 1;
			h2 {
				@include g.subh1();
			}
			.txt {
				padding: var(--gap3) 0;
				@include g.body2();
			}
			.btnblc {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.btn {
					margin-right: var(--gap2);
				}
				.txtlink {
					text-decoration: underline;
					color: #fff;
				}
			}
		}
	}
}
@include g.mq(sp) {
	#learning {
		padding: 0;
		.blockwrap {
			padding: 0;
		}
		#learn_img {
			padding-top: 200%;
			background: #000;
			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
				position: absolute;
				top: 0;left: 0;
				z-index: 1;
			}
			.paraimg {
				margin-top: 30%;
				padding-top: 220%;
				z-index: 0;
			}
			.title {
				width: 100%;
				top: var(--gap);
				left: 0;
				text-align: center;
				z-index: 2;
				h2 {
					@include g.subh1();
				}
				.txt {
					padding: var(--gap2) var(--gap3);
					@include g.body2();
				}
				.btnblc {
					flex-direction: column;
					.btn {
						margin: 0;
					}	
					.txtlink {
						margin-top: var(--gap2);
					}
				}
			}
		}
	}
}


}

