@use "global" as g; 

//================
// Product
//================
#product {

/* ***** KV **************** */
#kv {
	padding: 0;
	.blockwrap {
		max-width: 100%;
		align-items: flex-start;
	}
	#kvimg {
		position: relative;
		width: 50vw;
		padding-top: 50vw;
		border-bottom-right-radius: 15vw;
		overflow: hidden;
	}
	#kv_title {
		width: calc(100% - 50vw);
		padding-top: calc(var(--sec) * 1.5);
		padding-right: var(--gap);
		@include g.mq(w1280) {
			padding-top: 14rem;
		}
		h1 {
			@include g.head1();
		}
		.txt {
			padding: var(--gap2) 0;
		}
		.btn {
			color: #000;
			background: var(--green);
		}
	}
}
@include g.mq(w950) {
	#kv {
		.blockwrap {
			align-items: stretch;
			padding-right: 0;
		}
		#kvimg {
			width: 45vw;
		}
		#kv_title {
			width: calc(100% - 45vw);
			h1 {
				font-size: 6rem;
			}
		}	
	}
}

@include g.mq(sp) {
	#kv {
		.blockwrap {
			flex-direction: column-reverse;
			padding: 0;
		}
		#kvimg {
			width: 100%;
			padding-top: 120%;
			border-bottom-right-radius: 30vw;
		}
		#kv_title {
			width: 100%;
			padding: var(--gap3);
			padding-top: var(--gap);
			padding-bottom: 0;
			.txt {
				padding: var(--gap3) 0;
			}
		}
	}
}


/* ***** video **************** */
#video_sec {
	.blockwrap {
		max-width: 100%;
	}
	.video {
		&:hover {
			svg {
				transform: scale(1.2);
			}
		}
	}
	.videotxt {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;left: 0;
		background: rgba(0,0,0,0.4);
		@include g.flex_center();
		flex-direction: column;
		color: #fff;
		text-align: center;
		@include g.tra();
		h2 {
			@include g.head1();
		}
		.txt {
			max-width: 400px;
			padding: var(--gap2) 0;
		}
		svg {
			width: 36px;
			@include g.tra();
		}
	}
	.play .videotxt {
		opacity: 0;
		pointer-events: none;
	}
}
@include g.mq(sp) {
	#video_sec {
		.video {
			width: 100%;
			padding-top: 160%;
		}
		.videotxt {
			background: rgba(0,0,0,1);
			.txt {
				padding: var(--gap3) 0;
			}
		}
	}
}


/* ***** contents **************** */
#contents {
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0%;left: 0;
		background: linear-gradient(var(--wh1), var(--green), var(--wh1));
		opacity: 0.1;
		z-index: -1;
	}
	.blockwrap {
		max-width: 100%;
		padding: 0;
	}
	.blc {
		@include g.flex();
		width: 100%;
		padding-bottom: var(--sec);
		&:last-of-type {
			padding-bottom: 0;
		}
		.img_blc {
			max-height: 100vw;
			width: calc(50% - var(--gap));
			padding-top: calc(44% - var(--gap)/2);
			position: relative;
			border-top-right-radius: 10vw;
			overflow: hidden;
			img {
				@include g.imgcover();
			}
		}
		.txt_blc {
			width: 50%;
			padding: var(--gap) 0;
			.txt_group {
				padding-top: var(--gap);
			}
			.sub_title {
				padding-top: var(--gap2);
				font-size: 1.8rem;
				font-weight: bold;
				color: var(--dkgrey);
			}
			h2 {
				@include g.subh1();
			}
			.txt {
				padding-top: var(--gap3);
				color: var(--dkgrey);
			}
			.btn {
				margin-top: var(--gap);
				color: #000;
				background: var(--green);
			}
		}
		&:nth-of-type(even) {
			flex-direction: row-reverse;
			.img_blc {
				border-top-right-radius: 0;
				border-top-left-radius: 10vw;
			}	
			.txt_blc {
				padding-left: calc(var(--gap)*2);
				padding-right: 0;
			}
		}
		&:nth-of-type(odd) {
			.txt_blc {
				padding-right: calc(var(--gap)*2);
				padding-left: 0;
			}
		}
	}
}


@include g.mq(sp) {
	#contents {
		.blockwrap {
			padding: 0 var(--gap3);
		}	
		.blc {
			padding-bottom: var(--sec);
			.img_blc {
				width: 100%;
				padding-top: 120%;
			}
			.txt_blc {
				width: 100%;
				padding: 0;
				.sub_title {
					font-size: 1.6rem;
				}	
				.txt {
					padding-top: var(--gap3);
				}
				.btn {
					margin-top: var(--gap3);
				}
			}
			&:nth-of-type(even) {
				.txt_blc {
					padding-left: 0;
				}
			}
			&:nth-of-type(odd) {
				.txt_blc {
					padding-right: 0;
				}
			}
		}
	}
}



}




