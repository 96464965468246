@use "global" as g; 

html {
	margin : 0;
	padding : 0;
	font-size:62.5%;
}
@include g.mq(w1280) {
	html {
		font-size: 55%;
	}
}
@include g.mq(w950) {
	html {
		font-size: 50%;
	}
}
@include g.mq(sp) {
	html {
		font-size:62.5%;
	}
}

body {
	margin : 0;
	padding : 0;
	width : 100%;
	height:100%;
	font-family: "acumin-pro-semi-condensed", sans-serif;
	font-size: 1.8rem;
	font-weight: 300;
	z-index: 1;
	color:#000;
	line-height:1.3;
	background: var(--wh1);
	-webkit-font-smoothing: antialiased;
	/* font-feature-settings : "palt"; */
	@include g.mq(sp) {
		font-size: 1.6rem;
	}
}

h1,h2,h3,h4,strong,em {
	font-weight: 700;
}
a {
	display: inline-block;
	color:#000;
	text-decoration: none;
	word-break: break-word;
	@include g.tra();
	&:hover{
		color: var(--orange);
	}
}
img {
	width: 100%;
	height: auto;
	display: block;
	vertical-align: text-bottom;
}
li{
	list-style:none;
}
.svg-block {
  display: block;
}
object {
	pointer-events: none;
}
.svg-link object {
	overflow:visible;
}
svg {
	width: 100%;
	height: auto;
}
::selection {
 background: #333;
 color:#fff;
}
::-moz-selection {
 background: #333;
 color:#fff;
}
form ::selection {
 background: #333;
 color:#fff;
}
form ::-moz-selection {
 background: #333;
 color:#fff;
}
:focus {
	outline: 0;
}
.nolink {
	cursor: inherit;
	pointer-events: none;
}
.br_sp {
	display: none;
}
.br_pc {
	display: inline-block;
}


/* ***** Common **************** */
.pc { display: inline; }
.sp { display: none; }
@include g.mq(sp) {
	.pc { display: none; }
	.sp { display: inline; }
}

/* ***** Layout **************** */
#wrapper {
	overflow: hidden;
}
section {
	position: relative;
	padding: var(--sec) 0;
}
.blockwrap {
	@include g.flex();
	position: relative;
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	padding: 0 var(--gap);
}
.mid {
	max-width: 1080px;
	margin: 0 auto;
}
.thin {
	max-width: 820px;
	margin: 0 auto;
}
.block {
	width: 100%;
}

@include g.mq(sp) {
	.blockwrap {
		padding: 0 var(--gap3);
	}
}


