@use "global" as g; 

//================
// Single
//================
/* ***** Single KV **************** */
.single {
#kv {
	@include g.kvimg_title();
}
}


//================
// Resource Single
//================
#resources.single {

/* ***** contents **************** */
#contents {
	.blockwrap {
		max-width: 820px;
		padding: 0 var(--gap);
	}
	&:after {
		display: none;
	}
	@include g.contents();
	@include g.mq(sp) {
		.blockwrap {
			max-width: 100%;
			padding: 0 var(--gap3);
		}
	}	
}


.archive_sec {
	padding-top: 0;
	.title_blc {
		width: 100%;
		text-align: center;
		h2 {
			display: inline-block;
			padding: var(--gap) 0;
			padding-top: var(--gap2);
			@include g.head2();
			line-height: 1;
			border-top: 1px solid var(--orange);
		}
	}
}
@include g.mq(sp) {
	.archive_sec {
		.title_blc {
			h2 {
				padding: var(--gap) var(--gap2);
				padding-top: var(--gap2);
			}
		}
	}
}

#kv{
	padding-bottom: 6rem;
}


}