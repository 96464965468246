
$breakpoints: (
	'sp': 'screen and (max-width: 767px)',
	'pc': 'screen and (min-width: 768px)',
	'w1280': 'screen and (max-width: 1280px)',
	'w950': 'screen and (max-width: 950px)',
	'min1440': 'screen and (min-width: 1440px)',
) !default;

@mixin mq($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

:root {
	--sec: 12rem;
	--gap: 8rem;
	--gap2: 4rem;
	--gap3: 3rem;
	--wh1: #FEFCF6;
	--wh2: #FDFCF5;
	--wh3: #F6F4EF;
	--orange: #F44500;
	--blue: #A4CAD9;
	--dkblue: #122A40;
	--dkblue2: #193752;
	--green: #00EC95;
	--yellow: #EBF261;
	--dkgrey: #414141;
}
@include mq(sp) {
	:root {
		--sec: 8rem;
		--gap: 6rem;
		--gap2: 2rem;
	}
}

@mixin body1() {
	font-size: 1.8rem;
	@include mq(sp) {
		font-size: 1.6rem;
	}
}
@mixin body2() {
	font-size: 2rem;
	@include mq(sp) {
		font-size: 1.6rem;
	}
}
@mixin nav1() {
	font-size: 1.4rem;
	@include mq(sp) {
		font-size: 1.6rem;
	}
}
@mixin nav2() {
	font-size: 1.5rem;
}
@mixin nav3() {
	font-size: 1.8rem;
}
@mixin nav_sub() {
	font-size: 1.5rem;
	font-weight: 300;
	color: #414141;
}
@mixin head1() {
	font-family: "acumin-pro",sans-serif;
	font-size: 7rem;
	font-weight: 700;
	line-height: 1;
	@include mq(sp) {
		font-size: 4.5rem;
	}
}
@mixin head2() {
	font-family: "acumin-pro",sans-serif;
	font-size: 5.8rem;
	font-weight: 700;
	line-height: 1;
	@include mq(sp) {
		font-size: 4rem;
	}
}
@mixin subh1() {
	font-family: "acumin-pro",sans-serif;
	font-size: 4.8rem;
	font-weight: 700;
	line-height: 1.1;
	@include mq(sp) {
		font-size: 4rem;
	}
}
@mixin subh2() {
	font-family: "acumin-pro",sans-serif;
	font-size: 3.8rem;
	font-weight: 700;
}
@mixin subh3() {
	font-family: "acumin-pro",sans-serif;
	font-size: 3.3rem;
	font-weight: 700;
}

@mixin tra() {
	transition: 0.2s;
}
@mixin flex() {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;	
}
@mixin flex_center() {
	display:flex;
	justify-content: center;
	align-items: center;
}
@mixin bg() {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
@mixin imgcover() {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
}
@mixin bfaf() {
	content:'';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

@mixin kvmin($mh:750px) {
	min-height: $mh;
	@include mq(sp) {
		min-height: 0;
	}
}

@mixin sound_btn() {
	i {
		display: block;
		width: 3px;
		background: #000;
		border-radius: 2px;
		animation: level 1.5s infinite alternate;
		&:nth-of-type(1) {
			height: 8px;
			animation-duration: 0.6s;
			animation-delay: 0.2s;
		}
		&:nth-of-type(2) {
			height: 14px;
			animation-duration: 0.4s;
			animation-delay: 0.1s;
		}
		&:nth-of-type(3) {
			height: 22px;
			animation-duration: 0.8s;
			animation-delay: 0.4s;
		}
		&:nth-of-type(4) {
			height: 16px;
			animation-duration: 0.5s;
			animation-delay: 0.3s;
		}
		&:nth-of-type(5) {
			height: 6px;
			animation-duration: 0.8s;
			animation-delay: 0.6s;
		}
	}
	@keyframes level {
		0% {transform: scaleY(0.3);}
		50% {transform: scaleY(0.6);}
		100% {transform: scaleY(1.2);}
	}	
}

@mixin sound_offbtn() {
	i {
		opacity: 0.1;
		animation-play-state: paused;
		&:nth-of-type(1) {
			transition-delay: 0s;
		}
		&:nth-of-type(2) {
			transition-delay: 0.03s;
		}
		&:nth-of-type(3) {
			transition-delay: 0.06s;
		}
		&:nth-of-type(4) {
			transition-delay: 0.09s;
		}
		&:nth-of-type(5) {
			transition-delay: 0.12s;
		}
	}	
}


@mixin contents(){
	div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video {
		margin-bottom: 2rem;
	}
	h1,h2,h3,h4,h5,h6 {
		margin-bottom: 3rem;
		font-size: 3rem;
		font-weight: 500;
	}
	ul {
		padding-left: 1em;
	}
	li {
		list-style: auto;
	}
	@include mq(sp) {
		h1,h2,h3,h4,h5,h6 {
			font-size: 2.2rem;
		}	
	}	
}


@mixin middle_title() {
	width: 100%;
	padding-bottom: var(--gap);
	text-align: center;
	h1,h2 {
		padding: 0;
		padding-bottom: var(--gap2);
		@include head1();
		text-align: center;	
	}
	.txt {
		max-width: 400px;
		margin: 0 auto;
	}
	@include mq(sp) {
		padding-bottom: var(--gap3);
		text-align: left;
		h1,h2 {
			text-align: left;	
		}
		.txt {
			max-width: 100%;
		}
	}
}


@mixin kvimg_title(){
	padding-top: 0;
	.blockwrap {
		max-width: 100%;
		align-items: center;
		padding: 0;
	}
	#kvimg {
		position: relative;
		width: 50%;
		padding-top: 50%;
		border-bottom-right-radius: 15vw;
		overflow: hidden;
		img {
			@include imgcover();
		}
	}
	#kv_title {
		width: 50%;
		padding: var(--gap);
		padding-right: calc(var(--gap) * 2);
		padding-top: 14rem;
		.info {
			padding-bottom: var(--gap2);
		}
		h1 {
			@include subh2();
			padding-bottom: var(--gap2);
		}
	}
	
	@include mq(w950) {
		.blockwrap {
			align-items: stretch;
		}	
	}

	@include mq(sp) {
		.blockwrap {
			padding: 0;
		}
		#kvimg {
			width: 100%;
			padding-top: 120%;
			border-bottom-right-radius: 30vw;
		}
		#kv_title {
			width: 100%;
			padding: var(--gap3);
			padding-bottom: 0;
			.info {
				padding-bottom: var(--gap3);
			}	
			h1 {
				padding-bottom: var(--gap3);
			}
		}
	}		
}


@mixin logoSwiper(){
	mix-blend-mode: darken;
	height: 70px;
	.swiper-wrapper {
		transition-timing-function: linear;
	}
	.swiper-slide {
		padding: 0 3%;
		.img {
			position: relative;
		}
		img {
			height: 100%;
			width: 100%;
			@include imgcover();
			object-fit: contain;
			filter: grayscale(100%);
			opacity: 0.7;
			mix-blend-mode: darken;
		}	
	}
	@include mq(sp) {
		overflow: visible;
		.swiper-slide {
			padding: 0 5%;
		}
	}
}
