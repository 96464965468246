@use "global" as g; 

//================
// Form Template
//================
.form_template {

#parawrap1 {
	height: 100%;
}
#para1 {
	width: 24vw;
	top: 5%;
	left: 5vw;
}
#para2 {
	display: none;
}
#para3 {
	width: 24vw;
	top: 85%;
	left: 95vw;
	z-index: 0;
}
@include g.mq(sp) {
	#parawrap1 {
		height: 100vh;
	}	
	#para1 {
		width: 48vw;
		top: 12%;
		left: 5vw;
	}
	#para2 {
		width: 32vw;
		top: 40%;
		left: 85vw;
		display: block;
	}
	#para3 {
		display: none;
	}
}

#contact_sec {
	padding-top: calc(var(--sec)*2);
	.blockwrap {
		@include g.flex();
		.blc {
			width: 50%;
		}
		.col2 {
			padding-left: var(--gap);
		}
	}
	#titleblc {
		h1 {
			padding-bottom: var(--gap2);
			@include g.head1();
		}
		a {
			text-decoration: underline;
		}
	}
	#form_blc {
		width: 100%;
		margin-top: var(--gap);
	}
	#contact_parts {
		#commentSwiper {
			align-items: stretch;
			font-size: 1.8rem;
			border-radius: 10rem 0 10rem 0;
			background: rgba(244, 69, 0, 0.1);
			.swiper-slide {
				height: auto;
				padding: calc(var(--gap2)*2);
				display: flex;
				flex-direction: column;
				justify-content: center;
				.txt {
					opacity: 0.7;
				}
				.role {
					padding-top: var(--gap2);
					font-weight: bold;
				}
				.name {
					font-weight: 500;
				}
			}
			.snav {
				position: absolute;
				top: 50%;
				width: 10px;
				aspect-ratio: 1/2;
				clip-path: polygon(0 0,100% 50%,0 100%);
				background: #000;
				transform: translate(-50%,-50%);
				z-index: 9;
				cursor: pointer;
				&.prev {
					left: calc(var(--gap2)/2);
					transform: translate(50%,-50%) rotate(180deg);
				}
				&.next {
					right: calc(var(--gap2)/2);
				}
			}
		}
	}
	.award_blc {
		@include g.flex();
		align-items: center;
		padding-top: var(--gap2);
		img {
			width: 12rem;
			height: 12rem;
		}
		p {
			flex: 1;
			padding-left: var(--gap2);
			font-size: 2rem;
			font-weight: bold;
		}	
	}
}
@include g.mq(sp) {
	#contact_sec {
		padding-top: calc(var(--sec)*3);
		.blockwrap {
			@include g.flex();
			.blc {
				width: 100%;
			}
			.col2 {
				padding-top: var(--gap);
				padding-left: 0;
			}
		}	
		#titleblc {
			h1 {
				padding-bottom: var(--gap3);
			}
		}
		.award_blc {
			img {
				width: 10rem;
				height: 10rem;
			}
			p {
				font-size: 1.6rem;
			}	
		}
	}
}


}
