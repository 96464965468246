@use "global" as g; 

/* ***** Footer **************** */
#footer {
	position: relative;
	padding-top: var(--sec);
	padding-bottom: 20px;
	@include g.nav1();
	color: var(--wh1);
	background: #000;
	.blockwrap {
		align-items: flex-start;
	}
	#f1 {
		width: 300px;
		margin-right: var(--gap);
		#footer_logo {
			width: 240px;
			margin-bottom: var(--gap2);
		}
		.txt {
			margin-bottom: var(--gap2);
			font-size: 1.6rem;
		}
		.sns {
			ul {
				display: flex;
				align-items: center;
				li {
					width: 26px;
					margin-right: 20px;
					a {
						path {
							fill: var(--wh1);
							@include g.tra();
						}
						&:hover {
							path {
								fill: var(--orange);
							}
						}
					}
				}
			}
		}
	}
	#f2 {
		flex: 1;
		@include g.flex();
		font-weight: 500;
		.col2 {
			width: 50%;
			margin-bottom: var(--gap3);
			.ex + .ex {
				margin-top: 20px;
			}
		}
		strong {
			padding-bottom: 10px;
			@include g.nav3();
			font-weight: 700;
		}
		ul {
			display: flex;
			flex-direction: column;
			li {
				padding: 5px 0;
				a {
					color: var(--wh1);
					transition-property: color;
					&:hover {
						color: var(--orange);
					}
				}
			}
		}
		.contact {
			strong {
				display: block;
			}
			li {
				display: flex;
				align-items: center;
				img {
					width: 16px;
					margin-right: 10px;
				}
			}
		}
	}
	#newsletter {
		width: 360px;
		strong {
			display: block;
			padding-bottom: 2rem;
			@include g.nav3();
			font-weight: 700;
		}
		@include g.mq(w1280) {
			width: 100%;
			padding-left: calc(300px + var(--gap));
		}
		@include g.mq(sp) {
			width: 100%;
			margin: var(--gap2) 0;
			padding-left: 0;
		}
	}
	
	#icons {
		display: flex;
		width: 100%;
		margin-bottom: var(--gap2);
		img {
			width: 80px;
			margin-right: 20px;
		}
	}
	#copyright {
		width: 100%;
		@include g.flex();
	}
}
@include g.mq(sp) {
	#footer {
		font-size: 1.4rem;
		#f1 {
			width: 100%;
			margin-right: 0;
			.txt {
				margin-bottom: var(--gap);
			}
			.sns {
				margin-bottom: var(--gap);
			}
		}
		#f2 {
			flex: 1;
			@include g.flex();
			font-weight: 500;
			.col2 {
				width: 100%;
				margin-bottom: 0;
				.ex {
					margin-bottom: 15px;
					border-bottom: 1px solid #525252;
				}
				.ex + .ex {
					margin-top: 0;
				}
			}
			ul {
				display: block;
				li {
					width: 100%;
					padding: 5px 0;
					padding-left: 20px;
					&:last-of-type {
						margin-bottom: 20px;
					}
					a {
						width: 100%;
					}
				}
			}
			.contact {
				padding-top: var(--gap2);
			}
		}
		#icons {
			margin-top: 20px;
			margin-bottom: var(--gap2);
			img {
				width: 60px;
			}
		}	
		#copyright {
			p + p {
				margin-top: 10px;
			}
		}
	}
}


/* ***** footer empowering **************** */
#footer_empowering {
	body.nofoot_emp & {
		display: none;
	}
	position: relative;
	padding-top: var(--gap);
	padding-bottom: calc(var(--sec)*1.5);
	color: #fff;
	background: var(--dkblue);
	.title {
		h2 {
			@include g.head1();
		}
		#txt_loop {
			height: 5rem;
			margin: 30px 0;
			font-size: 3rem;
			.swiper-slide {
				@include g.flex_center();
				justify-content: flex-start;
				height: 4rem;
			}
		}
		.btnblc {
			text-align: left;
		}
	}
	#parawrap3 {
		height: 100%;
		top: 5vw;
	}
	#para3_1,#para3_3,#para3_4 {
		z-index: 3;
	}
	#para3_2,#para3_5,#para3_7 {
		z-index: 2;
	}
	#para3_6,#para3_8 {
		z-index: 1;
	}
	#para3_1 {
		width: 16vw;
		top: 0;
		right: 10vw;
	}
	#para3_2 {
		width: 14vw;
		top: 36%;
		right: 21vw;
	}
	#para3_3 {
		width: 16vw;
		top: 56%;
		right: 29vw;
	}
	#para3_4 {
		width: 16vw;
		top: 0;
		right: -14vw;
	}
	#para3_5 {
		width: 14vw;
		top: 36%;
		right: -1vw;
	}
	#para3_6 {
		width: 12vw;
		top: 66%;
		right: 11vw;
	}
	#para3_7 {
		width: 14vw;
		top: 90%;
		right: 21vw;
	}
	#para3_8 {
		width: 10vw;
		top: 66%;
		right: -10vw;
	}
}
@include g.mq(sp) {
	#footer_empowering {
		padding-top: 70vh;
		padding-bottom: 15vh;
		.title {
			#txt_loop {
				height: 3rem;
				margin-top: var(--gap2);
				margin-bottom: var(--gap);
				font-size: 1.9rem;
				.swiper-slide {
					@include g.flex_center();
					justify-content: flex-start;
					height: 3rem;
				}
			}
			.btnblc {
				text-align: left;
			}
		}
		#parawrap3 {
			height: 50%;
			top: 10vh;
		}
		#para3_1 {
			width: 40vw;
			top: 0;
			left: 76vw;
			right: auto;
		}
		#para3_2 {
			width: 34vw;
			top: 34%;
			left: 50vw;
			right: auto;
		}
		#para3_3 {
			width: 40vw;
			top: 56%;
			left: 25vw;
			right: auto;
		}
		#para3_4 {
			display: none;
		}
		#para3_5 {
			width: 34vw;
			top: 34%;
			left: 100vw;
			right: auto;
		}
		#para3_6 {
			width: 32vw;
			top: 64%;
			left: 76vw;
			right: auto;
		}
		#para3_7 {
			width: 34vw;
			top: 90%;
			left: 50vw;
			right: auto;
		}
		#para3_8 {
			display: none;
		}
	}
}

/* ***** footer about **************** */
#footer_our_story {
	display: none;
	body.our_world & {
		display: block;
	}
	position: relative;
	padding-bottom: calc(var(--sec)*3);
	text-align: center;
	color: #fff;
	font-size: 1.8rem;
	@include g.mq(sp) {
		font-size: 1.6rem;
	}
	.blockwrap {
		align-items: center;
	}
	.bg{
		@include g.bfaf();
		top:auto; bottom: -30%;
		@include g.bg();
	}
	&:after {
		@include g.bfaf();
		height: 70%;
		top:auto; bottom: -30%;
		background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
	}
	.blockwrap {
		flex-direction: column;
	}
	h2 {
		padding-bottom: var(--gap2);
		@include g.head1();
	}
	.txt {
		max-width: 620px;
		margin: 0 auto;
		margin-bottom: var(--gap2);
	}
}

@include g.mq(sp) {
	#footer_our_story {
		padding-bottom: calc(var(--sec)*4);
		&:after{
			height: 180vw;
		}
		h2 {
			padding-bottom: var(--gap);
			font-size: 4rem;
		}
		.txt {
			margin-bottom: var(--gap);
		}
	}
}





