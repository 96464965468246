@use "global" as g; 

//================
// Page
//================
.basic_pages {

#pagecontents {
	padding-top: calc(var(--sec)*1.5);
	.title {
		width: 100%;
		padding-bottom: var(--gap);
		text-align: center;
		h1 {
			@include g.head2();
		}
	}
	.txt_block {
		@include g.contents();
	}
}
@include g.mq(sp) {
}

}




